
import {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonIcon,
    IonButton,
    modalController,
    //IonButton,
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { useRoute, useRouter } from "vue-router";
import { reactive, ref, onMounted } from "vue";

import apiInterventi from "../services/interventi";
import { openToast } from "../services/toast";

export default {
    name: "ModalFirmaIntervento",
    props: {
        data: {
            type: String,
            required: true,
        },
        firma: {
            type: String,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonTitle,
        IonIcon,
        IonButton,
        //IonButton,
    },
    setup(props, context) {
        const route = useRoute();
        const router = useRouter();
        const interventoID = route.params.id;
        const hasSigned = ref(false); //check if client has signed, otherwise don't save

        /********************************************************* */
        const canvas = ref<HTMLCanvasElement | null>(null);
        const x = ref(0);
        const y = ref(0);
        /********************************************************* */

        const firma_da_cancellare = ref(false);

        /**
         * ! Print corrent page title
         */
        //console.log(props.data);
        let reference = "";
        if (props.data === "tecnico") {
            reference = "tecnico";
        } else {
            reference = "cliente";
        }

        /**
         * ! Populate canvas with firma (in caso operatore or customer return in this page after initial signature)
         */
        function populateCanvas() {
            if (props.firma && props.firma.length != 0) {
                const sign = new Image();
                const ctx = canvas.value.getContext("2d");
                sign.onload = function() {
                    ctx.drawImage(sign, 0, 0);
                };
                sign.src = `data:image/png;base64,${props.firma}`;
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = async () => {
            if (firma_da_cancellare.value) {
                await modalController.dismiss({ da_cancellare: true, tipo: reference });
            } else {
                modalController.dismiss();
            }
            //modalController.dismiss();
        };

        /**
         * ! Canvas data and operations
         */
        const point = reactive({
            x: 0,
            y: 0,
        });
        const moving = ref(false);
        const ctx = ref(null);
        const firstDraw = ref(false);

        //TOUCH START
        function mStart(e) {
            //console.log(e);
            hasSigned.value = true;

            /* const x = e.touches[0].clientX - e.target.offsetLeft;
            const y = e.touches[0].clientY - e.target.offsetTop; // get the coordinates of the touch point on the canvas
            point.x = x;
            point.y = y;
            ctx.value.beginPath();
            moving.value = true; */
            const pos = { x: e.touches[0].clientX, y: e.touches[0].clientY };
            const rect = e.target.getBoundingClientRect();
            const x_rel = pos.x - rect.left;
            const y_rel = pos.y - rect.top;
            const x = Math.round((x_rel * e.target.width) / rect.width);
            const y = Math.round((y_rel * e.target.height) / rect.height);
            point.x = x;
            point.y = y;

            ctx.value.beginPath();
            moving.value = true;
            firstDraw.value = true;
        }
        //SLIDE START
        function mMove(e) {
            if (moving.value) {
                /* const x = e.touches[0].clientX - e.target.offsetLeft;
                const y = e.touches[0].clientY - e.target.offsetTop; // get the coordinates of the touch point on the canvas
                ctx.value.moveTo(point.x, point.y); // move the path to the specified point in the canvas without creating a line (starting point)
                ctx.value.lineTo(x, y); // add a new point, and then create a line from the point to the last specified point in the canvas, without creating a line
                ctx.value.stroke(); // draw
                point.x = x;
                point.y = y; // reset the point coordinate to the previous coordinate */
                const pos = { x: e.touches[0].clientX, y: e.touches[0].clientY };
                const rect = e.target.getBoundingClientRect();
                const x_rel = pos.x - rect.left;
                const y_rel = pos.y - rect.top;
                const x = Math.round((x_rel * e.target.width) / rect.width);
                const y = Math.round((y_rel * e.target.height) / rect.height);
                ctx.value.lineCap = "round";
                ctx.value.moveTo(point.x, point.y); // move the path to the specified point in the canvas without creating a line (starting point)
                ctx.value.lineCap = "round";
                ctx.value.lineTo(x, y); // add a new point, and then create a line from the point to the last specified point in the canvas, without creating a line
                ctx.value.lineCap = "round";
                ctx.value.stroke(); // draw
                point.x = x;
                point.y = y;
            }
        }
        //SLIDE END
        function mEnd() {
            if (moving.value) {
                ctx.value.closePath(); // stop drawing
                moving.value = false; // turn off the draw switch
            }
        }
        function clearCanvas() {
            ctx.value = canvas.value.getContext("2d");
            const dataURL = canvas.value.toDataURL();
            ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
            hasSigned.value = false;
            //da usare per cancellare firma da intervento nel caso in cui io prema su "pulisci firma" e torno indietro
            firma_da_cancellare.value = true;
        }
        function getCanvas() {
            const dataURL = canvas.value.toDataURL();
            //console.log(dataURL);
            return dataURL;
        }

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit() {
            //Get canvas content and remove 'data:image/png;base64,' from string
            if (hasSigned.value) {
                const sign = getCanvas().replace("data:image/png;base64,", "");
                //console.log(getCanvas());
                if (sign) {
                    //console.log(sign);
                    await modalController.dismiss({ firma: sign, tipo: reference });
                } else {
                    await modalController.dismiss();
                }
            } else {
                openToast("Non puoi confermare senza aver firmato", "toast_danger");
            }
        }

        function confermaFirme() {
            //Get canvas content and remove 'data:image/png;base64,' from string
            const sign = getCanvas().replace("data:image/png;base64,", "");
            if (sign) {
                closeModalOnSubmit();
            }
        }

        onMounted(() => {
            canvas.value.width = 120;
            canvas.value.height = 600;

            ctx.value = canvas.value.getContext("2d");
            ctx.value.lineWidth = 1;

            populateCanvas();
        });

        return {
            //salvaFirmaCliente,
            x,
            y,
            canvas,
            //touch
            mStart,
            mMove,
            mEnd,
            clearCanvas,
            getCanvas,
            //new field
            arrowBackOutline,
            closeModal,
            reference,
            //Chiusura modale alla conferma firme (passo indietro base64 firma)
            confermaFirme,
        };
    },
};
