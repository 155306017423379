import axios from "axios";

export default {
    async saveFirmaIntervento(intervento_id, data) {
        const response = await axios.post(`rest/v1/edit/tickets_reports/${intervento_id}`, data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response.data.data[0]);
        //console.log(response);
        return response;
    },

    async addInterventoPhotos(intervento_id, data) {
        const response = await axios.post(`rest/v1/edit/tickets_reports/${intervento_id}`, data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response.data.data[0]);
        //console.log(response);
        return response;
    },
};
